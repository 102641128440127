/* Archivo styles.css */

/* Estilo para el contenedor general del contenido de Quill */
.quill-content {
  @apply leading-relaxed; /* Espaciado entre líneas y color base */
}

/* Estilo para texto en negrita */
.quill-content strong {
  @apply font-bold;
}

/* Estilo para texto en cursiva */
.quill-content em {
  @apply italic;
}

/* Estilo para texto subrayado */
.quill-content u {
  @apply underline;
}

/* Estilo para encabezados */
.quill-content h1 {
  @apply text-3xl font-bold mt-6 mb-4;
}

.quill-content h2 {
  @apply text-2xl font-semibold mt-5 mb-3;
}

/* Lista ordenada con números personalizados */
ol {
  counter-reset: li; /* Inicializa el contador para listas ordenadas */
}

.quill-content ol {
  padding-left: 24px;
  font-weight: 400;
}

.quill-content ol li {
  font-size: 24px;
  line-height: 30px;
  position: relative;
  padding-left: 24px;
}

.quill-content ol li::before {
  content: counter(li) ". ";
  counter-increment: li;
  position: absolute;
  left: 0;
  font-size: 24px;
  line-height: 30px;
}

/* Lista desordenada con bullets personalizados */
.quill-content ul {
  padding-left: 24px;
  font-weight: 400;
}

.quill-content ul li {
  font-size: 24px;
  line-height: 30px;
  position: relative;
  padding-left: 16px;
}

.quill-content ul li::before {
  content: '•';
  position: absolute;
  left: 0;
  font-size: 24px;
  line-height: 30px;
}



/* Estilo para enlaces */
.quill-content a {
  @apply text-blue-500 underline hover:text-blue-700;
}

/* Estilo para texto con formato adicional */
.quill-content p {
  @apply text-base mt-3 mb-3;
}

/* Estilo para el recurso de tipo post */

.quill-content p {
  @apply leading-relaxed;
  font-size: 26px;
  line-height: 35px;
  font-weight: 400;
}

.quill-content h1 {
  @apply text-4xl font-bold mt-6 mb-4;
}

.quill-content h2 {
  @apply text-3xl font-semibold mt-5 mb-3;
}

.quill-content h3 {
  @apply text-2xl font-semibold mt-4 mb-2;
}

/* Estilos para el recurso de tipo story */
.req-post p {
  @apply text-[30px];
  line-height: 40px;
  font-weight: 400;
}

.req-post h1 {
  @apply text-[46px];
  color: black;
}

.req-post h2 {
  @apply text-[44px];
  color: black;
}

h2.inst-post {
  @apply text-[42px];
  font-weight: 400;
}

/* Imágenes de fondo para cada post */
.post-card {
  background-image: url('../../../../assets/img/post-base.jpg');
}

.story-card {
  background-image: url('../../../../assets/img/story-base.jpg');
}

.story-card-2 {
  background-image: url('../../../../assets/img/story-base-2.jpg');
}
