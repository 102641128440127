/* @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;700&display=swap'); */
@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Encode+Sans:wght@400;700&display=swap');

@font-face {
	font-family: 'Custom' !important;
	src: url("./font/DM_Sans/DMSans-VariableFont_opsz,wght.ttf");
}


@layer base {

	@font-face {
		font-family: 'Custom' !important;
		src: url("./font/DM_Sans/DMSans-VariableFont_opsz,wght.ttf");
	}

	html {
		font-family: 'Custom' !important;
		-webkit-font-smoothing: antialiased;
		/* letter-spacing: -0.5px; */
	}



}

input.defaultCheckbox::before {
	content: url(../src/assets/svg/checked.svg);
	color: white;
	opacity: 0;
	height: 16px;
	width: 16px;
	position: absolute;
	left: 50%;
	transform: translate(-50%, 0px);
}

input:checked.defaultCheckbox::before {
	opacity: 1;
}

button.ant-switch {
	background-color: #e1e1e1;
}

button.ant-switch.ant-switch-checked {
	background-color: #FFD625;
}

button.ant-btn-primary {
	background-color: #FFD625 !important;
}